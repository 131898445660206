<template>
  <div>
    <dashboard-page-title
      @addEvent="$router.push({ name: 'hospitalAddDoctor' })"
      :showAddBtn="true"
      :showFilter="true"
      :showSearch="true"
      :showExportation="true"
      >الاطباء</dashboard-page-title
    >

    <main-table
      :fields="fields"
      query=""
      list_url="clinic/doctors"
    ></main-table>
  </div>
</template>
<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      fields: [
        { label: "Id", key: "id", class: "text-right", sortable: true },
        // { label: 'الصورة', key: 'order_address.name', class: 'text-right' },
        { label: "الاسم", key: "full_name", class: "text-right" },
        {
          label: "رقم الهاتف",
          key: "primary_phone_number",
          class: "text-right",
        },
        {
          label: "تكلفة الكشف",
          key: "prices",
          class: "text-right",
          type: "multi-text",
        },
        {
          label: "عدد الكشوفات",
          key: "examinations_count",
          class: "text-right",
        },
        {
          label: "التحكم",
          key: "actions",
          type: "actions",
          class: "text-right",
          actions: [
            {
              text: "View",
              icon: "ri-eye-line",
              color: "info",
              url: "clinic/doctors/:id",
            },
            {
              text: "Edit",
              icon: "ri-edit-line",
              color: "primary",
              url: "clinic/doctors/:id",
              ifNavigate: true, // Indicate that this action navigates
              routeName: "hospitalAddDoctor", // Name of your route
              //getParams: (dataItem) => ({ id: dataItem.id }), // Function to get params
            },
            {
              text: "Delete",
              icon: "ri-delete-bin-line",
              color: "danger",
              url: "clinic/doctors/:id",
              titleHeader: "حذف",
              question: "؟هل متأكد أنك تريد حذف هذا الطبيب",
              textContent: "name",
              textDeleteButton: "بالتأكيد, حذف",
              textCancelButton: "إلغاء",
              showAlert: true,
            }
          ],
        },
        // { label: "Created At", key: "created_at", class: "text-right" },
      ],
    };
  },
};
</script>
